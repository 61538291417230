@use '@fortellis/style/variables' as frc-variables;
@use '@material/button/mixins' as mdc-button-mixins;
@use '@material/ripple/mixins' as mdc-ripple-mixins;
/* Imports */
@use '@fortellis/style/index.scss';
@use '@fortellis/circular-progress/circular-progress.scss';
@use '@fortellis/empty-area/empty-area.scss';
@use '@fortellis/expansion-panel/expansion-panel.scss';
@use '@fortellis/file-input/file-input.scss';
@use '@fortellis/flex/flex.scss';
@use '@fortellis/logo/logo.scss';
@use '@fortellis/page/page.scss';
@use '@fortellis/panel/panel.scss';
@use '@fortellis/spinner/spinner.scss';
@use '@fortellis/typography/typography.scss';
@use '@fortellis/footer/footer.scss';
@use '@cdk-prod/fortellis-header/header.scss';

.ag-theme-balham .ag-root-wrapper {
  border: none 0px !important;
}

.api-error-filter {
  border-color: #c4c5c8;
  border-style: solid;
  border-radius: 0.5em;
  height: 45vh;
}

.api-error-filter {
  margin: 0 1ch 2ch 1ch;
  height: 15vh;
  //background-color:lightgray;
}

.custom-date-filter {
  width: 380px !important;
  background-color: #f0e9f1 !important;
}

.timespan-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 16px;
  position: absolute;
  width: 300px;
  height: 280px;
  left: 274px;
  top: 292px;
  background: #ffffff;
  box-shadow: 0px 2px 7px 1px rgba(21, 27, 37, 0.45);
  border-radius: 8px;
  flex: none;
  order: 4;
  flex-grow: 0;
  z-index: 4;
}

.flex {
  display: flex;
}

.action-buttons {
  display: flex;
  padding-left: 145px;
  justify-content: space-around;
}

.action-buttons-space-around {
  width: 20px;
}

.start-time-input {
  border-radius: 8px 0 0 8px !important;
}

.end-time-input {
  border-radius: 0 8px 8px 0 !important;
}

.api-error-title {
  border-radius: 0.5em;
  //height: 10vh;
  background-color: #f3f3f3;
  display: flex;
  padding-left: 16px;
  padding-top: 18px;
  padding-bottom: 18px;
  align-items: center;
}

.api-error-list {
  border-color: #c4c5c8;
  border-style: solid;
  border-radius: 0.5em;
  height: 484px !important;
  margin-bottom: 15px;

  //background-color:lightgray;
  .ag-root-wrapper {
    overflow: visible;

    .ag-root {
      overflow: visible;

      .ag-header {
        overflow: visible;

        .ag-header-viewport {
          overflow: visible; //ok

          .ag-header-row {
            overflow: visible;

            .ag-header-cell {
              overflow: visible;
            }
          }
        }
      }
    }
  }
}

.api-error-list > div {
  height: 475px !important;
}

.load-new-records {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-new-records-text {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  color: #151b25 !important;
}

.load-new-records-button {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
}

.table-pagination-bar > div {
  border: 1px solid white !important;
}
.async-api-error-list {
  border-color: #c4c5c8;
  border-style: solid;
  border-radius: 0.5em;
  height: 492px !important;
  //background-color:lightgray;
}

.async-api-error-list > div {
  height: 475px !important;
}

.api-error-detail {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.api-error-caption {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 40px;
  color: #151b25;
}

.api-error-time {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(21, 27, 37, 0.701961);
}

.custom-header {
  padding: 0px 0px 0px 0px !important;
  border: none 0px !important;
}

.apiErrorTable {
  width: 98% !important;
  border: none !important;
  margin: 8px 16px 16px 16px !important;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.table-header-dailog {
  display: flex;
  justify-content: left;
  margin-bottom: 6px;
}

.dialog-table-title {
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 20px;
  padding: 16px 16px;
}

.search-box-table {
  width: 674px !important;
  margin-bottom: 10px;
}

.search-box-table-dailog {
  width: 347px !important;
  margin-bottom: 10px;
}

.download-csv-button {
  margin-top: 13px !important;
}

.error-list-title {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 3px;
}

.error-list-description {
  color: #000000;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;
}

.error-list-header-title {
  width: 216x;
  height: 32px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.18px;
  color: #000000;
}

.error-list-footer-title {
  display: flex;
  justify-content: center;
  height: 14px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #151b25;
}

.errortable {
  margin: 16px 16px 16px 16px;
}

.api-error-header {
  padding: 0px;
  background: #ffffff;
  border-radius: 8px;

  /* Inside auto layout */
}

.api-error-env {
  display: flex;
  margin-left: 36px;
  margin-right: 16px;
}

.api-error-version {
  display: flex;
}

.api-error-menu {
  max-width: 570px !important;
}

.api-error-menu-child {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.api-error-menu-parent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.api-error-filter-set {
  display: flex;
  margin-top: 16px;
  margin-bottom: 8px;
}

.async-api-error-filter-set {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  justify-content: flex-start;
  align-items: center;
}

.api-error-chip-set {
  display: flex;
}

.api-error-timestamp-text {
  display: flex;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  margin-right: 8px;

  /* Text & Icons/Light/Active Dark */

  color: #151b25;
}

.api-error-status-code-text {
  display: flex;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  margin-right: 8px;

  /* Text & Icons/Light/Active Dark */
  margin-left: -6px;
  margin-top: 4px;
  color: #151b25;
}

.api-filter-chip-timestamp {
  display: flex;
  align-items: baseline;
  margin-right: 8px;
}

.async-api-filter-chip-timestamp {
  display: flex;
  margin-right: 8px;
}

.timeStamp {
  margin-top: 8px;
}

.appSelection {
  align-items: center;
}
.async-api-error-timestamp-text {
  display: flex;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  margin-right: 8px;

  /* Text & Icons/Light/Active Dark */

  color: #151b25;
  margin-top: 6px;
}

.async-api-error-refresh-button {
  margin-left: 16px;
}

.api-filter-chip-statuscode {
  display: flex;
  align-items: baseline;
  margin-right: 8px;
}

.api-method-chip-set {
  display: flex;
  align-items: baseline;
  margin-right: 8px;
}

.api-source-chip-set {
  display: flex;
  align-items: baseline;
  margin-right: 8px;
}

.api-request-id-input {
  width: 20vw;
  margin-right: 16px;
}

.api-error-divider-page {
  margin-bottom: 32px;
}

.app-error-list-btn-rest > button {
  display: flex;
  justify-content: flex-end;
}

.app-error-list-btn-rest {
  display: flex;
  margin-left: auto;
}

.app-error-list-btn-async {
  display: flex;
  margin-left: auto;
}

.cal-dlg-wrapper {
  div {
    div {
      overflow: visible;
    }
  }
}

.cal-dlg-picker-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 1vh;
  margin-bottom: 3vh;
}

.cal-dlg-date-picker {
  width: 13vw;
}

#time-filter-dd {
  &-h1,
  &-m1,
  &-s1,
  &-h2,
  &-m2,
  &-s2 {
    max-width: fit-content;
    max-height: 20rem;
    z-index: 60;

    button {
      z-index: 60;

      svg {
        background-color: rgba(21, 27, 37, 0.701961);
        border-color: rgba(21, 27, 37, 0.701961);
      }
    }
  }
}

.api-error-time-wrapper {
  width: 9vw;
}

.date-time-header {
  font-size: 19px;
  font-weight: 600;
}

.api-error-time-unit {
  display: flex;
  justify-content: space-evenly;
}

.api-error-time-start-end {
  font-size: 1.3rem;
  margin-top: 1.5vh;
  margin-bottom: 1vh;
}

.bucket-dlg-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.bucket-dlg-cmd-btn {
  display: flex;
  justify-content: space-around;
  width: 10rem;
  margin-right: 1vw;
}

.time-validity-error {
  display: flex;
  margin-top: 1vh;

  svg {
    height: 14px;

    :last-child {
      fill: #eb0000;
    }
  }

  span {
    color: #eb0000;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }
}

.api-error-req-id-render {
  width: 100%;

  div {
    flex-grow: 1;
  }
}

.url-renderer-generator {
  overflow: scroll;
}

.api-error-request-id-column {
  padding-left: 5px;

  svg {
    fill: rgba(21, 27, 37, 0.7);
  }
}

.api-error-tooltip {
  display: flex;
  position: absolute;
  background-color: black;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  z-index: 4;
  top: -2vh;
  left: -9vw;
  margin-top: -0.25rem;

  a {
    font-weight: 900;
    color: #ffffff;
    text-decoration: underline;
  }
}

.api-error-request-id-column {
  svg {
    fill: #151b25 !important;
    opacity: 70%;
  }
}

.api-error-open-new {
  height: 20px;

  svg {
    fill: #ffffff !important;
    height: 15px;
  }
}

.eventId-text {
  margin-top: 10px;
  width: 156px;
  height: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #151b25;
}

.async-api-event-id-verify-button {
  width: 41px;
  height: 18px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.25px;
  color: #5e2368;
  margin-left: 16px;
}

.api-name-sub-header {
  width: 690px;
  height: 80px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.fdn-api-name-sub-header {
  width: 690px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  color: #000000;
  display: flex;
}

.event-id-input {
  width: 300px;
  box-sizing: border-box;
  border: 1px solid rgba(21, 27, 37, 0.5);
  border-radius: 8px;
}

.event-id-input > div {
  width: 300px;
}

.event-delivery-attempt {
  width: 614px;
  display: inline-flex;
}

.event-delivery-attempt > div:nth-child(2) {
  width: 328px !important;
  margin-right: 16px;
}

.dialog-error-not-found {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  box-sizing: border-box;
  text-align: center;
}

.frc-progress-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 80px - 252px - 40px);

  &--full-screen {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    z-index: 9;

    .frc-progress-spinner__scrim {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: -1;
    }
  }

  &.text {
    flex-direction: column;
  }
}

.fdn-metric--error-messaging {
  position: absolute;
  width: 621px;
  height: 50px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #000000;
}

.event-delivery-status-indicator__icon {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  margin-left: 8px;

  &--grey {
    background-color: frc-variables.$fortellis-system-inactive;
  }

  &--blue {
    background-color: #074faf;
  }

  &--yellow {
    background-color: #fa9c19;
  }

  &--green {
    background-color: #09ad00;
  }

  &--red {
    background-color: #eb0000;
  }
}

.async-api-metric-daialog {
  margin-bottom: 50px;
}

.verify-eventId-button {
  background-color: #f0e9f1 !important;
  color: #5e2368 !important;
}

.table-column-class {
  cursor: pointer;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.tool-tip-render {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.table-column-class:hover {
  font-weight: 600;
}

.custom-column-Header {
  margin-bottom: 3px;
  display: flex;
  flex-direction: column;
}

.tooltip-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  height: 14px;
  color: #ffffff;
}

.container {
  display: flex;
}

.left-div {
  margin-right: 10px;
}

.right-div {
  margin-left: 10px;
}

// .verify-event-table
//   > div
//   > div
//   > div
//   > div
//   > div
//   > div
//   > div:last-child
//   > div
//   > div {
//   span {
//     display: none;
//   }
// }

.info-icon-css {
  margin-top: 10px;
}

.verify-button {
  margin-top: 2px;
}

.download-csv-button {
  margin-right: 44px;
}

.copiable-cell {
  padding-right: 0.5rem !important;
  .copiable-content {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tooltip-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .ag-react-container {
    div:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
