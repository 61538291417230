.app-metrics-header {
  border-radius: 0.5em;
  background-color: #f3f3f3;
  padding-left: 16px;
  padding-top: 18px;
  padding-bottom: 18px;
  margin-bottom: 16px;
}

.app-metrics-header-dropdown {
  background-color: white;
}

.traffic-count-data {
  display: flex;
  justify-content: space-between;
  padding-right: 35px;
  padding-left: 15px;
}

.traffic-error-count-data {
  @extend .traffic-count-data;
  padding-left: 0;
  gap: 1.5rem;
}

.traffic-count-header {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #000000;
  margin-bottom: 20px;
}

.traffic-count-sub-header1 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #000000;
  width: 100px;
}

.traffic-count-sub-header2 {
  @extend .traffic-count-sub-header1;
  width: 0;
}

.traffic-count-sub-header3 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: 0.15px;
  color: #000000;
  width: 100px;
}

.traffic-count-digit {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 72px;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #000000;
}

.traffic-count-digit-pending {
  margin: 10px 0;
}

.traffic-count-block-border {
  width: 0;
  height: 180px;
  border: 1px solid #c4c6c8;
  margin: 0 1.5rem;
}

.traffic-count-section-border {
  max-width: 1208px;
  height: 0;
  border: 1px solid #c4c6c8;
  margin-top: 16px;
  margin-bottom: 20px;
}
