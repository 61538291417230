/* Variables, Mixins, and Functions */
@use '@fortellis/style/variables' as frc-variables;
@use '@material/button/mixins' as mdc-button-mixins;
@use '@material/ripple/mixins' as mdc-ripple-mixins;
/* Imports */
@use '@fortellis/style/index.scss';
@use '@fortellis/circular-progress/circular-progress.scss';
@use '@fortellis/empty-area/empty-area.scss';
@use '@fortellis/expansion-panel/expansion-panel.scss';
@use '@fortellis/file-input/file-input.scss';
@use '@fortellis/flex/flex.scss';
@use '@fortellis/logo/logo.scss';
@use '@fortellis/page/page.scss';
@use '@fortellis/panel/panel.scss';
@use '@fortellis/spinner/spinner.scss';
@use '@fortellis/typography/typography.scss';
@use '@fortellis/footer/footer.scss';
@use '@cdk-prod/fortellis-header/header.scss';

.grecaptcha-badge {
  visibility: hidden;
}

.fdn-table--no-outline.mdc-data-table {
  border: none;
}

.fdn-table--fullwidth {
  width: 100%;
  border: none;

  table {
    width: 100%;

    thead {
      tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }
}

.mdc-card.frc-user-card.mdc-card--outlined {
  border-width: 0px;
  border-radius: 0px;
}

.mdc-layout-grid {
  padding-left: 0;
  padding-right: 0;
}

.frc-page__cell--action {
  .fdn-content__action-button {
    @include mdc-button-mixins.outline-width(1px);
    white-space: nowrap !important;
  }
}

.frc-chip-set {
  padding-bottom: 12px;
}

.frc-chip {
  &.mdc-chip--selected {
    background-color: frc-variables.$fortellis-primary;
    color: white !important;
  }
}

.frc-page {
  padding-bottom: 7em;
}

.frc-page__entityRegistration {
  padding-bottom: 25em;
}

.frc-page__description {
  white-space: pre-wrap;
}

.frc-page__metrics {
  margin-left: auto;
  max-width: 1400px;
}

.metrics-dropdown {
  min-height: 2rem;
}

a {
  text-decoration: none;
  color: frc-variables.$fortellis-primary;
}

.fdn-checkbox-label {
  &:hover {
    cursor: pointer;
  }

  &--disabled {
    color: rgba(0, 0, 0, 0.26);

    &:hover {
      cursor: default;
    }
  }
}

.panel-anchor {
  margin: 0;
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  border-color: frc-variables.$fortellis-primary;
  background-color: frc-variables.$fortellis-primary;
}

.fdn-circular-progress {
  &--fixed {
    width: 100%;
    height: 100%;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.54);
    overflow: hidden;
  }
}

.fdn-cover {
  background: url('assets/landingpage-bg.jpg');
  background-size: cover;
  box-sizing: border-box;
  font-family: frc-variables.$fortellis-font-family;
  padding-top: 200px;

  &--mobile {
    padding-top: 90px;
  }

  &__headline {
    width: 1200px;
  }

  &__text {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 17px;
  }

  &__title {
    font-size: 82px;
    line-height: 88px;
    font-family: frc-variables.$fortellis-font-family-title;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 2px;
  }

  &__subtitle {
    font-size: 24px;
    line-height: 42px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 36px;
  }

  &__cta-buttons {
    margin-bottom: 120px;

    a + a {
      margin-left: 50px;
    }
  }

  &__button {
    @include mdc-button-mixins.filled-accessible(transparent);
    @include mdc-button-mixins.outline-color(white);
    @include mdc-button-mixins.outline-width(1px);
    @include mdc-button-mixins.horizontal-padding(24px);
    font-weight: 600 !important;

    &--highlighted {
      @include mdc-button-mixins.filled-accessible(white);
      @include mdc-button-mixins.ink-color(#272e36);
      @include mdc-button-mixins.outline-width(1px);
      @include mdc-button-mixins.horizontal-padding(24px);
      @include mdc-ripple-mixins.states(
        $color: black,
        $ripple-target: '.mdc-button__ripple'
      );
      min-width: 175px;
      font-weight: 600;
    }
  }
}

.fdn-page {
  &__header {
    color: var(--mdc-theme-text-primary-on-background);
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    max-width: 1200px;
    margin: 10px auto;

    &-breadcrumbs {
      padding: 8px 0px;
      font-size: 16px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.54);

      i {
        margin: 0px 6px;
      }
    }
  }

  &__body {
    width: 100%;
    max-width: 1200px;
    margin: 32px auto;
  }
}

.fdn-content {
  margin-bottom: 63px;

  &__header {
    .fdn-content {
      &__title {
        color: #232323;
        font-size: 36px;
        font-weight: bold;
        line-height: 44px;
      }

      &__action {
        margin-left: auto;

        .fdn-content__action-button {
          border-width: 1px;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  &__description {
    color: var(--mdc-theme-text-secondary-on-background);
    font-size: 16px;
    font-weight: 500;
    margin: 16px 0;

    a {
      color: frc-variables.$fortellis-primary;
    }
  }

  &__subdescription {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.54);
  }

  &__subheader {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    font-weight: 600;

    &--invalid {
      color: #b00020;
    }
  }

  &__subheader-helper-text {
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.03333em;
    text-decoration: inherit;
    text-transform: inherit;
    margin: 0;
    color: #b00020;
  }
}

.fdn-file-button {
  white-space: nowrap;
  margin-bottom: 20px;
}

/** responsive styles */
@media (max-width: 1039px) {
  .fdn-cover {
    &__headline {
      width: inherit;
      text-align: center;
    }

    &__title {
      font-size: 45px;
      line-height: 70px;
      letter-spacing: 5.9px;
    }

    &__subtitle {
      font-size: 18px;
      font-weight: 300;
      line-height: 22px;
    }

    &__cta-buttons {
      a,
      button {
        width: 75%;
      }

      a + a,
      a + a > button {
        margin-top: 25px;
        margin-left: 0px;
      }
    }
  }

  .fdn-page {
    &__header {
      margin: 10px 16px;
    }

    &__body {
      margin: 32px 16px;
      width: inherit;
    }
  }
}

/** responsive styles */
/** responsive styles */
@media (max-width: 1039px) {
  .fdn-content {
    &__action {
      align-self: flex-start !important;
      margin-top: 8px;
    }

    &__header {
      flex-direction: column;
    }

    &__empty-area {
      .mdc-typography--subtitle1 {
        padding: 0 8px;
      }
    }
  }
}

/** responsive styles */

.fdn-tab-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &--solution-page {
    margin: 24px 0;
  }
}

.fdn-tab {
  &--disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .mdc-tab__content {
    color: frc-variables.$fortellis-text-secondary-dark;
    font-size: 13px;
    font-weight: 600;
  }

  &.mdc-tab--active {
    .mdc-tab__content {
      color: frc-variables.$fortellis-primary;
    }
  }
}

.mdc-list-item {
  &:hover {
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.32);
  }
}

.error-link {
  margin-left: 4px;
}

.frc-text-field {
  &.mdc-text-field--filled {
    .mdc-floating-label {
      transform: translateY(-106%) scale(0.75);
    }
  }

  &--margin-top {
    margin-top: 16px;
  }
}

.frc-text-field.mdc-text-field,
.frc-text-field.mdc-text-field--invalid.mdc-text-field--with-trailing-icon {
  .mdc-text-field__icon.rmwc-circular-progress {
    color: frc-variables.$fortellis-primary;
  }
}

.frc-select {
  &--margin-top {
    margin-top: 16px;
  }

  .mdc-floating-label {
    transform: translateY(-106%) scale(0.75);
  }

  .mdc-select__anchor {
    outline: 0;
  }
}

.fdn-back-button {
  .fdn-back-button-content {
    margin: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.App__signingPreviewComplete {
  margin: 200px auto;
  width: 20%;
}

.app-details-title {
  height: 40px;
  width: 200px;
  color: #151b25;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
}

.app-api-integration-header {
  height: 40px;
  width: 281px;
  color: #151b25;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  padding-bottom: 5px;
}

.app-api-integration-subheader {
  padding-bottom: 15px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #151b25;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.app-details-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 400px;
}

.app-details-body > div:first-child div:last-child {
  display: flex;
  justify-content: flex-end;
}

.app-details-body > div:nth-child(2) span {
  font-weight: 500;
  height: 1.35rem;
}

.app-list-caption {
  height: 20px;
  width: 102px;
  color: #151b25;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 20px;
  margin: 12px 16px;
}

.app-category-list-wrapper {
  box-sizing: border-box;
  height: 308px;
  width: 100%;
  border: 1px solid #151b25;
  border-radius: 8px;
}

.app-type-list-wrapper {
  box-sizing: border-box;
  height: 140px;
  width: 100%;
  border: 1px solid #151b25;
  border-radius: 8px;
}

.app-category-list {
  list-style-type: none;
  overflow-y: auto;
  height: 250px;
  padding-left: 0;
  position: relative;
}

.app-type-list {
  list-style-type: none;
  overflow-y: auto;
  height: 100px;
  padding-left: 0;
  position: relative;
}

.app-category-list-item {
  display: flex;
  justify-content: flex-start;
}

.app-category-label > span {
  color: #6b2574;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.app-badge-count {
  height: 22px;
  width: 37px;
  border: 1px solid #151b25;
  border-radius: 11px;
  display: flex;
  justify-content: center;
}

.app-badge-count > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-radio-wrapper {
  width: 23vw;
}

.app-badge-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-api-list-wrapper-v2 {
  box-sizing: border-box;
  height: 750px;
  width: 100%;
  border: 1px solid #151b25;
  border-radius: 8px;
}

.app-api-list-v2 {
  list-style-type: none;
  overflow-y: auto;
  height: 675px;
  padding-left: 0;
  position: relative;
}

.app-api-list-wrapper {
  box-sizing: border-box;
  height: 588px;
  width: 100%;
  border: 1px solid #151b25;
  border-radius: 8px;
}

.app-api-list {
  list-style-type: none;
  overflow-y: auto;
  height: 513px;
  padding-left: 0;
  position: relative;
}

.app-api-list-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  .app-api-list-pagination-display {
    width: 8.25em;
    text-align: center;
    font-family: 'Open Sans', 'Roboto', sans-serif;
    font-size: 14px;
  }
}

.app-api-list-item {
  display: flex;
  justify-content: flex-start;
}

.app-api-filter-v2 {
  display: flex;
  justify-content: space-between;
  height: 800px;
}

.app-api-filter-v3 {
  display: flex;
  justify-content: space-between;
}

.app-api-filter {
  display: flex;
  justify-content: space-between;
  height: 640px;
}

.app-api-filter-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-api-filter-wrapper-l {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 10px;
  width: 35%;
}
.app-api-filter-wrapper-contract-ui-l {
  display: flex;
  flex-direction: column;
  margin-right: 2%;
  width: 23%;
}

.app-api-filter-wrapper-r {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  width: 65%;
  gap: 2vh;
}

.solution-api-list-wrapper-r {
  display: flex;
  flex-direction: column;
  width: 75%;
  border: 1px solid #c4c5c8;
  height: 66vh;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.app-selected-api {
  background: unset;
  height: 20px;
  width: 83px;
  color: #6b2574;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 20px;
}

.app-pricing-header {
  height: 32px;
  width: 172px;
  color: #151b25;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 32px;
  padding-top: 25px;
  padding-bottom: 5px;
}

.app-pricing-subheader {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 24px;
  color: #151b25;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.app-selected-api-list div {
  border: none;
}

.app-selected-api-list > div > div:nth-child(2) > div > div {
  border: none;
}

.app-selected-api-name,
.app-selected-multi-api-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
}
.selected-api-name {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  display: flex;
}
.selected-apis {
  display: flex;
  flex-direction: column;
}
.api-name-and-icon-wrapper {
  display: flex;
  flex-direction: row;
}
.sales-icon-container {
  display: flex;
  align-items: center;
}
.app-selected-api-publisher {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.terms-not-applicable-section {
  padding-left: 24px;
}

.app-selected-multi-api-name {
  color: #3030ff;
}

.selected-api-publisher-name {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.selected-api-name {
  color: var(--text-icons-light-active-dark, #000);
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.selected-publisher-name {
  color: var(--text-icons-light-active-dark, #000);
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.view-fields-tooltip {
  display: flex !important;
  flex-direction: column;
  color: var(--text-icons-dark-active-white, #fff);
  font-family: 'Roboto';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  background-color: #000000;
  line-height: 14px;
}

.view-fields-dialog {
  width: 850px !important;
}

.view-fields-dialog-title {
  white-space: normal;
}

.view-fields-table-container {
  width: 100% !important;
  height: 500px !important;
}

.view-fields-table-header {
  margin-bottom: 10px;
}

.view-fields-table {
  height: 100px !important;
}

.view-fields-table-large {
  height: 170px !important;
}

.app-pricing-info {
  padding-left: 1rem;
  height: 20px;
  color: #6b2574;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.app-pricing-list {
  padding-left: 0.1vw;
}

.app-pricing-list-item {
  list-style-type: none;
  height: 84px;
}

.app-pricing-radio-wrapper {
  height: 31px;
}

.app-pricing-list-item span:last-child {
  height: max-content;
  width: 175px;
  color: #151b25;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.app-pricing-list-item-description {
  padding-left: 3.2vw;
}

.app-pricing-item-save {
  padding: 1rem;
  text-align: end;
}

.app-advance-pricing-item-save {
  text-align: end;
}

.app-review-terms-doc {
  height: 48.55vh;
  width: 80vw;
}

.app-api-accepted-terms,
.app-api-configured-fields,
.app-api-accepted-webhook {
  display: flex;
}

.app-api-accepted-terms svg:last-child,
.app-api-configured-fields svg:last-child,
.app-api-accepted-webhook svg:last-child {
  fill: #09ad00 !important;
}

.app-api-accepted-webhookUrl {
  overflow: scroll;
}

.api-integrations-table-button {
  padding-left: 0px;
}

.one-click-accept-message {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 32px;
  padding-top: 3vh;
  padding-bottom: 5px;
}

.one-click-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 17rem;
}

.one-click-button {
  font-size: 1.5rem;
  width: 8rem;
}

.one-click-consent-instruction {
  margin-bottom: 2vh;
}

.one-click-consent-input {
  height: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.download-pdf-button {
  font-size: 1.5rem;
  width: 9rem;
  padding-left: 1200px;
}

.download-pdf-wrapper {
  padding-left: 1200px;
}

.consent-data-wrapper {
  padding-left: 20px;
}

.app-view-wrapper {
  margin: 1rem 2rem;
}

.app-view-back {
  color: #6b2574;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.app-error-list-btn-back-error {
  display: flex;
  margin-bottom: 8px;
}

.app-view-details-wrapper {
  margin: 2rem 12rem;
}

.app-view-app-title {
  display: flex;
  justify-content: space-between;
}

.app-view-app-status-details {
  display: flex;
  align-items: end;
  margin-left: auto;
  flex-direction: column;
}

.app-view-app-status-indicator {
  display: flex;
  flex-direction: row;
}

.app-view-app-name {
  color: #151b25;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 500;
  line-height: 40px;
}

.app-view-update-details {
  color: #151b25;
  line-height: 24px;
  margin: 0.2rem 0;
  font-family: 'Montserrat';
}

.app-view-app-details {
  box-sizing: border-box;
  border: 1px solid #151b25;
  border-radius: 8px;
  margin: 2rem 0;
}

.app-view-sub-section-main-header {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 32px;
  margin: 1.5rem 1.25rem;
  margin-right: 2vw;
}

.view_api_fields_button:hover {
  background-color: transparent !important;
}

.field-name-div {
  display: contents;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.field-name-div-link {
  display: contents;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.field-check-box {
  width: 48px;
}

.field-name-tooltip {
  width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.view-fields-footer {
  padding-top: 12px;
  padding-left: 30px;
}

.text-capitalize {
  text-transform: capitalize;
}

.app-metrics-credentials-heading {
  margin-left: 20px;
  margin-right: 27px;
}

.app-view-api-data-row {
  display: flex;
  margin: 0.75rem 0;
}

.app-view-sub-section-sub-header {
  margin-left: 1.3vw;
  width: 12vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-view-sub-section-component {
  display: flex;
}

.app-view-sub-section-copy-icon {
  display: flex;
}

.app-view-sub-section-data {
  margin-right: 2rem;
  font-family: 'Montserrat';
  line-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-view-sub-section-description {
  margin-right: 2rem;
  font-family: 'Montserrat';
  line-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60rem;
  word-break: break-word;
}

.app-view-sub-section-sell {
  font-size: 16px;
  font-family: 'Open Sans';
  font-weight: 400;
  line-height: 24px;
  margin-left: 20px;
  margin-bottom: 16px;
  width: 94%;
}

.app-view-sub-section-sell-numeric-text {
  font-family: 'Roboto';
}

.app-view-sell-section-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(21, 27, 37, 0.701961);
  mix-blend-mode: normal;
  opacity: 0.7;
}

.app-view-certification-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 32px;
  margin-bottom: 32px;
}

.app-view-sub-section-footer-buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 32px;
  margin-bottom: 32px;
}

.app-view-sub-section-footer-buttons > div {
  display: flex;
  justify-content: flex-end;
  margin-right: 2vw;
  margin-bottom: 2vh;
  stroke: #6b2574;
}

.app-view-details-button {
  display: flex;
  justify-content: space-between;
}

.app-view-rest-button {
  margin-right: 11px;
}

.app-view-async-button {
  margin-right: 11px;
}

.app-view-metrics-button {
  margin-right: 14px;
}

.app-view-details-button > button {
  margin-right: 1.5ch;
}

.app-view-sub-section-selected-api {
  margin: 0 1vw 2vh 1vw;
}

.app-view-sub-section-selected-api div {
  border: none;
}

.app-view-sub-section-selected-api > div > div:nth-child(2) > div > div {
  border: none;
}

.app-view-sub-section-selected-api > div > div {
  padding: 0;
}

.app-view-sub-section-warning-icon {
  display: flex;
}

.app-view-sub-section-warning-icon svg:last-child {
  fill: #fa9c19 !important;
}

.app-view-popup-selected-api {
  display: flex;
}

.app-view-popup-selected-api div {
  border: none;
}

.app-view-popup-selected-api > div > div:nth-child(2) > div > div {
  border: none;
}

.app-view-popup-selected-api > div > div {
  padding: 0;
}

.app-view-api-connection-status-wrapper {
  display: flex;
  justify-content: space-between;
}

.app-view-api-connection-status-wrapper svg {
  stroke: #6b2574;
}

.app-view-api-connection-status {
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-view-retry-toast > div {
  max-width: 32rem;
  margin-top: 5rem;
}

.app-view-retry-toast button {
  color: #6b2574 !important;
}

.app-view-integration-error {
  max-width: 100vw;
  margin-top: 3ch;
}

.app-submit-erro-info {
  height: max-content;
  color: #6b2574;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.app-permission-warning-toast > div {
  max-width: 30rem;
  margin-top: 5rem;
}

.app-permission-warning-toast > div > :first-child {
  flex-direction: column;
}

.app-permission-warning-toast button {
  color: #6b2574 !important;
}

.api-instance-metrics {
  min-height: 800px;
  width: 100%;
  max-width: 1400px;
}

.metric-card {
  height: 300px;
  border: 1px #151b25;
  display: flex;
  justify-content: left;
  min-width: 450px;
  max-width: 580px;
}

.events-metrics-card {
  height: 300px;
  border: 1px #151b25;
  display: flex;
  justify-content: left;
  text-align: center;
}

.events-metrics-data {
  font-weight: bold;
}

.async-api-metrics-copy {
  font-size: 14px;
}

.large-text {
  font-size: 90px;
  margin-top: 30px;
  min-height: 150px;
}

.med-text {
  font-size: 50px;
  margin-top: 50px;
}

.small-text {
  font-size: 30px;
  margin-top: 70px;
}

.api-dashboard-card {
  margin-right: 3px;
  cursor: pointer;
}

.metric-sub-title {
  text-align: left;
}

.display-inline {
  display: inline;
}

.display-flex {
  display: flex;
}

.line-chart-header {
  line-height: 25px;
  display: inline;
}

.total-errors-data {
  justify-content: flex-end;
  font-size: 11px;
  width: 200px;
  line-height: 15px;
  white-space: pre-wrap;
  padding-top: 14px;
  padding-left: 20px;
}

.line-chart-legend {
  display: flex;
  justify-content: flex-end;
  height: 5.2ch;
}

.healthy-dashboard-api-card {
  box-sizing: border-box;
  height: 213px;
  width: 220px;
  border: 2px solid #09ad00;
  border-top: 7px solid #09ad00;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 5px;
}

.warning-dashboard-api-card {
  box-sizing: border-box;
  height: 213px;
  width: 220px;
  border: 2px solid #fa9c19;
  border-top: 7px solid #fa9c19;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 5px;
}

.api-metrics-chart-icon {
  position: relative;
  display: inline-block;
}

.api-metrics-chart-icon[data-title]:hover::after {
  content: attr(data-title);
  padding: 4px 8px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 110%;
  white-space: nowrap;
  border-radius: 5px;
  background: #000;
}

.danger-dashboard-api-card {
  box-sizing: border-box;
  height: 213px;
  width: 220px;
  border: 2px solid #eb0000;
  border-top: 7px solid #eb0000;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 5px;
}

.incomplete-dashboard-api-card {
  box-sizing: border-box;
  height: 213px;
  width: 220px;
  border: 1px solid #c4c5c8;
  border-top: 7px solid #c4c5c8;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 5px;
}

.icon-trending-up {
  height: 25px;
  width: 25px;
  fill: #09ad00;
}

.icon-trending-down {
  height: 25px;
  width: 25px;
  fill: #151b25;
}

.icon-warning {
  height: 25px;
  width: 25px;
  fill: #fa9c19;
}

.icon-danger {
  height: 25px;
  width: 25px;
  fill: #eb0000;
}

.subtitle {
  height: 14px;
  width: 60px;
  color: #151b25;
  font-family: 'Open Sans';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.incomplete-content {
  height: 14px;
  color: #151b25;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  margin: 15px;
}

.api-metrics-header-wrapper div {
  white-space: normal;
}

.api-metrics-chart-wrapper {
  margin-bottom: -4vh;

  svg {
    margin-left: -3.2vw;
  }
}

.api-metrics-grid {
  max-height: 67vh;
  overflow: auto;
}

.api-metrics-data-row {
  max-width: 80vw;
}

.api-details-card-v2-items {
  margin-left: 6px;
}

.api-details-card-v2-items li > div > div > div:last-child > div {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #151b25;
}

.api-details-card-v2-items li > div > div > div:first-child {
  max-width: 12vw;

  div {
    color: rgba(21, 27, 37, 0.701961);
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    mix-blend-mode: normal;
    opacity: 1.7;
  }
}

.api-documentation-action {
  display: inline-flex;
  height: 3.3ch;

  svg {
    height: 1.2rem;
  }

  span {
    font-size: 0.9rem;
    letter-spacing: 0.075rem;
  }
}

.add-pricing-plan-button {
  padding-top: 24px;
  padding-left: 14px;
}

.pricing-plan-button-action {
  padding-left: 16px;
}

.fdn-content__apispecs_count > span {
  font-family: 'Open Sans';
}

.icon_info_radial {
  color: '#6B2574' !important;
  height: 32px;
  width: 32px;
}

.api-error-list-btn {
  margin-right: 13px;
}

.app-error-list-btn {
  border: #eb0000 !important;
  border-style: solid !important;
  border-radius: 0.5em !important;
  margin-right: 1vw !important;

  span {
    color: #eb0000 !important;
  }
}

#personal_account_banner {
  width: auto;
  min-height: 48px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-left: -10px;
}

#specs_implemenatation_banner {
  width: 644px;
  height: 48px;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 24px;
  margin-left: -10px;
  margin-right: 27px;
}

#banner_api_spec_button {
  margin-right: 16px;
}

#api_section_content_details {
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  color: #151b25;
}

#app_section_content_details {
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  color: #151b25;
}

.Viewer-Fillable-SaveButton {
  position: unset !important;
}

.advance-plans-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 83% 17%;
  height: 60vh;
}

.advance-plans-container-single-tile {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 83% 17%;
  height: 50vh;
  margin-left: 106px;
  margin-right: 50px;
}

.advance-plans {
  display: -ms-grid;
  display: grid;
  width: max-content;
  overflow: scroll;
}

.advance-plan-tile {
  width: 256px;
  height: 390px;
  border: 1px solid #dcddde;
  margin: 10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 30% 53% 11%;
  grid-template-rows: 30% 53% 11%;
}

.advance-plan-tile-retired {
  border: 1px solid #e5e5e5;
  background: rgba(21, 27, 37, 0.05);
}

.arrow-button {
  border: none;
  background: none;
  cursor: pointer;
}

.arrow-disabled {
  cursor: no-drop;
  fill: rgba(21, 27, 37, 0.4);
}

.arrow-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.advance-pricing-dialog {
  overflow-x: auto;
  width: min-content;
  display: grid;
  grid-template-columns: 2% 96% 2%;
  grid-template-rows: 75% 15% 10%;
}

.advance-pricing-dialog-single-tile {
  overflow-x: auto;
  width: min-content;
  display: grid;
  grid-template-columns: 2% 96% 2%;
  grid-template-rows: 75% 25%;
}

.advance-pricing-dialog .toast {
  padding-left: 8px;
}

.advance-pricing-dialog-footer {
  display: grid;
  align-items: center;
  grid-column: 1;
  grid-row: 2;
}

.advance-pricing-dialog-footer-single-tile {
  display: grid;
  align-items: center;
  grid-column: 2;
  grid-row: 2;
}

.advance-plan {
  &-dialog-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin: 6px;
  }

  &-dialog-description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-left: 6px;
  }

  &-title {
    text-transform: capitalize;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #000000;
    margin-top: 25px;
  }

  &-pricing {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.18px;
    color: #000000;
    margin: 5px;
  }

  &-freetrial {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
    color: #000000;
  }

  &-description-container {
    margin-top: 0px;
  }

  &-description {
    width: 175px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
    color: #000000;
    margin-left: 15%;
    padding-top: 5px;
  }

  &-endpoint-container {
    padding-left: 18%;
    overflow: auto;
    overflow-x: hidden;
  }

  &-enpoint-container-label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
    color: #814588;
    margin-top: 20px;
  }

  &-arrow {
    fill: #6b2574;
    position: relative;
    left: -25px;
    top: -40px;
    cursor: pointer;
  }

  &-arrow-rotate {
    fill: #6b2574;
    position: relative;
    left: -25px;
    top: -40px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    cursor: pointer;
  }

  &-arrow-free {
    fill: #6b2574;
    position: relative;
    left: -25px;
    top: -20px;
    cursor: pointer;
  }

  &-arrow-rotate-free {
    fill: #6b2574;
    position: relative;
    left: -25px;
    top: -20px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    cursor: pointer;
  }

  &-container-quota-overage {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: #000000;
    margin-top: 5px;
  }

  &-divider {
    width: 100px;
    height: 1px;
    background: rgba(21, 27, 37, 0.3);
    margin-left: 20px;
    margin-top: -10px;
  }

  &-endpoint-list {
    position: relative;
    left: -45px;
    width: 255px;
    height: auto;
    max-height: 71px;
    background: #f3f5f7;
    top: -25px;
    overflow: auto;
  }

  &-endpoint-list-retired {
    background: #e5e5e5;
  }

  &-endpoint-list-collapsed {
    display: none;
  }

  &-endpoint-name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: #000000;
    padding-left: 43px;
    margin: 5px;
  }

  &-button-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
  }

  &-select {
    width: 66px;
    height: 36px;
    background: #f0e9f1;
    border-radius: 8px;
  }

  &-selected {
    height: 20px;
  }
}

.advance-plan-endpoint-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.advance-plan-endpoint-list::-webkit-scrollbar-thumb {
  background: rgba(21, 27, 37, 0.35);
  border-radius: 4px;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.advance-plan-endpoint-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.advance-plan-endpoint-container::-webkit-scrollbar-thumb {
  background: rgba(21, 27, 37, 0.35);
  border-radius: 4px;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.plan-retired-lable-container {
  display: flex;
  justify-content: center;
  height: 30px;
}

.plan-retired-label {
  background: #dae4f3;
}

.integration-history-container {
  display: grid;
  grid-template-rows: 1fr;
  width: 80%;
  margin: auto;
  margin-top: 4%;
}

.integration-history-header {
  height: 40px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  color: #151b25;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-bottom: 20px;
  margin-bottom: 50px;
}

.integration-history-description {
  width: 644px;
  height: 24px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(21, 27, 37, 0.701961);
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 5px;
}

.integration-table-header {
  width: 193px;
  height: 14px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #151b25;
  flex: none;
  order: 0;
  flex-grow: 1;
  border-right: 1px solid #c4c5c8;
  text-align: left !important;
}

.integration-table-header-child {
  width: 193px;
  height: 14px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #151b25;
  flex: none;
  order: 0;
  flex-grow: 1;
  text-align: left !important;
}

.integration-table-row {
  width: 165px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left !important;
  margin-top: 10px;
  padding: 15px !important;
  overflow: hidden !important;
}

.integration-table-row-expand {
  width: 30px;
  text-align: right !important;
  padding: 0px !important;
}

.integration-table-child-row {
  width: 180px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center !important;
  padding: 10px !important;
}

.integration-table-row-no-padding {
  width: 165px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left !important;
  margin-top: 10px;
}

.integration-table-row-gray {
  width: 165px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left !important;
  color: rgba(21, 27, 37, 0.701961);
  cursor: auto;
  padding: 10px 16px !important;
}

.integration-table-row-pricing {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left !important;
  color: #5e2368;
  font-weight: 600 !important;
  cursor: pointer;
  padding: 10px 5px 10px 0 !important;
}

.integration-table-row-pricing-disabled {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left !important;
  cursor: auto;
  padding: 10px 5px 10px 0 !important;
}

.integration-table-row-expiry-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.advance-plan-dialog-app-name {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 6px;
}

.tiered-pricing {
  margin-top: -20px;
}

.tiers {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.integration-history-row {
  border-bottom: 'none';
  height: '50px';
  box-shadow: 'inset 0px -1px 0px #DCDDDE';
}

.integration-history-row-gray {
  background: rgba(21, 27, 37, 0.05);
}

.table-data {
  padding-right: 0px !important;
}

.childs-rows {
  background: rgba(21, 27, 37, 0.05);
}

.expand-icon-rotate {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.app-async-metric-count-wrapper {
  display: flex;
}

.bar-chart-metric-card {
  height: 300px;
  border: 1px #151b25;
  display: flex;
  justify-content: left;
  min-width: 50vw;
  max-width: 100vw;
  // div {
  //   :first-child {
  //     margin-top: -1.5vh;
  //   }
  // }
}

.app-async-api-bar-wrapper {
  display: flex;
  //justify-content: center;
}

.app-async-api-bar-chart {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.app-async-api-bar-chart svg > g > g > text {
  font-weight: bold;
  fill: white;
}

.app-async-api-bar-legend-wrapper {
  display: flex;
  flex-direction: column;
}

.bar-legend-box-wrapper {
  display: flex;
  margin-top: 1vh;
}

.bar-legend-success-box {
  height: 22px;
  width: 22px;
  background: #0096d1;
  border-radius: 2px;
}

.bar-legend-failure-box {
  height: 22px;
  width: 22px;
  background: #ee5396;
  border-radius: 2px;
}

.bar-legend-unit-text {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-left: 0.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-async-metric-header {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  width: 235px;
  height: 28px;
}

.app-async-metric-failed-delivery {
  display: flex;
  justify-content: end;
}

.app-async-metric-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
}

.app-async-metric-count-header-wrapper {
  display: flex;
  justify-content: space-between;
}

.app-async-metric-count-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 5vw 0 5vw;
}

.bar-chart-tooltip-wrapper {
  background: white;
  width: max-content;
  border-radius: 5px;
  color: black;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.bar-chart-tooltip-count-item-wrapper {
  display: flex;
}

.bar-chart-tooltip-count-color-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.app-details-header-container {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
}

.api-view-api-status-indicator {
  display: flex;
  flex-direction: row;
}

.api-view-api-status-details {
  display: flex;
  align-items: end;
  margin-left: auto;
  flex-direction: column;
}

.api-details-header-container {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
}

.api-info-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
}

.api-details-header-container .dnp-api-name {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 600;
}

.api-details-sub-text {
  display: flex;
  align-items: center;
  gap: 4px;
}

.api-details-sub-text.key {
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
}

.api-details-sub-text.value {
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 400;
}

.app-info-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
}

.app-details-header-container .app-name-logo {
  height: 64px;
  width: 64px;
  font-weight: 600;
  background-color: #5b5f66;
  color: #ffffff;
  font-size: 56px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-details-header-container .dnp-app-name {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 600;
}

.app-details-sub-text {
  display: flex;
  align-items: center;
  gap: 4px;
}

.app-details-sub-text.key {
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
}

.app-details-sub-text.value {
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 400;
}

.app-details-sub-text.key.tnc {
  cursor: pointer;
  color: #5e2368;
}

.app-details-sub-text.value.link {
  text-decoration: none;
  color: #5e2368;
  font-weight: 600;
}

.legacy-pricing-dialog-tile {
  overflow-x: auto;
  display: grid;
  grid-template-columns: 15% 70% 15%;
  grid-template-rows: 75% 25%;
}

.legacy-pricing-dialog-single-tile {
  overflow-x: auto;
  width: min-content;
  display: grid;
  grid-template-columns: 2% 96% 2%;
  grid-template-rows: 100%;
}

.legacy-pricing-dialog {
  overflow-x: auto;
  width: min-content;
  display: grid;
  grid-template-columns: 2% 96% 2%;
  grid-template-rows: 100%;
}

.legacy-plans-container-tile {
  display: grid;
  -ms-grid-rows: 83% 17%;
  height: 50vh;
  margin-left: 70px;
  margin-right: 70px;
}

.legacy-plans-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 83% 17%;
  height: 100%;
}

.dialog-3-portal-width-single-tile {
  width: 350px;
  max-width: 350px !important;
  min-width: 350px !important;
}

.legacy-plans-container-single-tile {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 83% 17%;
  height: 100%;
  margin-left: 20px;
  margin-right: 50px;
}

.app-api-integrated-plans {
  display: flex;
  flex-direction: column;

  button {
    height: fit-content;
  }
}

.bold-text {
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.app-certification-payment-toast {
  margin-top: 70px;
  z-index: 5 !important;
  transform: translateX(0px);
  max-width: 38.25rem !important;
}
.verify-payment-toast {
  margin-top: 70px;
  z-index: 5 !important;
  transform: translateX(0px);
  max-width: 38.25rem !important;
}

.payment_checkout_tab {
  margin-top: 10px !important;
}

.verify_button {
  margin: 8px !important;
}

.verify-account {
  display: inline;
  cursor: pointer;
}

.verify-ach-account-button {
  cursor: pointer;
  color: var(--text-icons-light-primary-link-enabled, #5e2368);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.25px;
}

.micro-deposits {
  display: flex;
  justify-content: flex-end;
  margin-right: 35px;
  margin-bottom: 10px;
}

.verify-account label {
  cursor: pointer !important;
}

#dialog-micro_deposit h2 {
  margin-right: 4px;
  font-size: calc(min(4vw, 18px));
  height: 24px;
  color: #151b25;
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
}

.micro_deposit_label_text {
  margin-right: 4px;
  font-size: calc(min(4vw, 16px));
  height: 24px;
  opacity: 0.7;
  color: #151b25;
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
}

.micro_deposit_inlineInput-left {
  padding: 0px 10px 0px 0px;
}

.micro_deposit_inlineInput-right {
  padding: 0px 10px 0px 10px;
}

.verify_account_actions {
  margin-left: auto;
}

.verify_account_error {
  padding: 8px 16px;
  border: 2px solid #f443368c;
  border-radius: 9px;
  color: #f44336;
  max-width: 375px;
}

#dialog-micro_deposit .jSaQeL {
  padding: 5px 5px 5px 10px;
}

.verify-ach-account-dropdown {
  width: 98% !important;
}

.payment-card {
  display: flex;
  width: 518px;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 24px;
  background: var(--solid-grey-grey-50, #f3f3f3);
  border-radius: 8px;
}

.payment-image {
  display: flex;
  gap: 24px;
}

.payment-type-sub-header {
  color: var(--text-icons-light-active-dark, #151b25);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.18px;
  width: 178px;
}

.payment-info {
  color: var(--text-icons-light-active-dark, #151b25);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.payment-edit {
  width: 16px;
  height: 16px;
  margin-left: 8px;

  cursor: pointer &--disabled {
    cursor: 'not-allowed';
  }
}

.payment-sub-header-editing {
  margin-left: 156px;
}

.payment-delete {
  width: 16px;
  height: 16px;

  cursor: pointer &--disabled {
    cursor: 'not-allowed';
  }
}

.payment-method-header {
  color: var(--text-icons-light-active-dark, #151b25);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.18px;
  margin-top: 32px;
  margin-bottom: 4px;
}

.payment-method-description {
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
}

.cancel-dailog {
  width: 24px;
  height: 0px;
  flex-direction: inherit;
  position: fixed;
  top: 0px;
  right: 32px;
  margin: 16px;
}

.payment-method-icon {
  font-size: 64px !important;
}

.selected-api-text {
  color: #151b25;
  font-family: Montserrat;
  font-weight: 600;
}
.payment-checkout-dialog-spinner {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0px;
}

.app-view-certification-notify {
  height: 100%;
  width: 100%;
  margin: 2rem 0;
}

.app-view-certification-notify .group {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.app-view-certification-notify .notifications {
  align-items: flex-start;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}

.app-view-certification-notify .icon-frame {
  align-items: flex-start;
  align-self: stretch;
  background-color: #6b2574;
  border-radius: 8px 0px 0px 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 14px 8px 8px;
  position: relative;
}

.app-view-certification-notify .icon-sets-time-alarm {
  height: 24px;
  position: relative;
  width: 24px;
}

.app-view-certification-notify .content-frame {
  align-items: flex-end;
  align-self: stretch;
  background-color: #ffffff;
  border: 2px solid;
  border-color: #6b2574;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  justify-content: space-around;
  padding: 16px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 15%;
}

.app-view-certification-notify .message-frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  left: 16px;
  top: 16px;
}

.app-view-certification-notify .text-wrapper {
  color: #000000;
  font-family: 'Montserrat-SemiBold', Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
}

.app-view-certification-notify .certification-text {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  left: 16px;
  top: 52px;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.app-view-certification-notify .span {
  color: #000000;
}

.app-view-certification-notify .text-wrapper-2 {
  color: #000000;
  font-family: 'Open Sans-SemiBold', Helvetica;
  font-weight: 600;
}

.app-view-certification-notify .text-wrapper-3 {
  color: #000000;
  font-family: 'Open Sans-Bold', Helvetica;
  font-weight: 700;
  font-size: 14px;
}

.app-view-certification-notify .text-wrapper-4 {
  font-family: 'Open Sans-Bold', Helvetica;
  margin-top: 5px;
}

.app-view-certification-notify .buttons-instance {
  left: 978px !important;
  position: absolute !important;
  top: 36px !important;
}

.certify-app-button {
  margin-left: 30px;
  margin-bottom: 15px;
  width: 118px;
}

.view-tnc {
  color: #6b2574;
  margin-left: 2px;
  cursor: pointer;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.certify-app-button {
  margin-left: 30px;
  margin-bottom: 20px;
  width: 118px;
}
.wrapper {
  margin-bottom: 27px;
}

.app-view-app-details .base-button-instance {
  color: #151b25 !important;
  font-family: 'Open Sans-Regular', Helvetica !important;
  font-weight: 400 !important;
  margin-top: -1px !important;
}
.api-freetrial-date {
  color: #fff;
  font-size: 11px;
  font-style: 'normal';
  font-weight: 700;
  line-height: 1px;
  font-family: 'Open Sans';
  padding-top: 13px;
}

.api-freetrial-date-text {
  color: #fff;
  font-family: 'Open Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 1px;
}
.advance-plan-freetrial {
  width: 254px;
  height: 40px;
  flex-shrink: 0;
  background-color: #6b2574;
}
.advance-plan-freetrial-text {
  width: 254px;
  height: 40px;
  flex-shrink: 0;
}

.app-payment-description {
  height: 52px;
  width: 846px;
  position: relative;
}
.app-payment-description .notifications {
  align-items: flex-start;
  display: flex;
  height: 52px;
  left: 0;
  position: absolute;
  top: 0;
  width: 846px;
}
.app-payment-description .info-icon {
  align-items: center;
  align-self: stretch;
  background-color: #074faf;
  border-radius: 8px 0px 0px 8px;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 10px 10px 10px;
  position: relative;
}
.app-payment-description .content-frame {
  align-items: flex-end;
  align-self: stretch;
  background-color: #ffffff;
  border: 2px solid;
  border-color: #074faf;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  position: relative;
  color: #000000;
  font-family: 'Montserrat-SemiBold', Helvetica;
  font-size: 16px;
  font-weight: 200;
}
.app-payment-description .message-frame {
  align-items: flex-start;
  display: inline-flex;
  left: 14px;
  position: absolute;
}
.payment-methodflow-description {
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 36px;
  margin-top: 16px;
}
.payment-methodflow-description .bold-text {
  font-family: 'Open Sans-Bold', Helvetica;
  font-weight: 700;
  font-size: 16px;
}

.app-view-free-trial-notify {
  height: 95px;
  width: 100%;
  margin: 2rem 0;
}

.app-view-free-trial-notify .group {
  height: 95px;
}

.app-view-free-trial-notify .overlap-group {
  height: 95px;
  position: relative;
}

.app-view-free-trial-notify .notifications {
  align-items: flex-start;
  display: flex;
  height: 95px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.app-view-free-trial-notify .alarm-icon {
  align-items: flex-start;
  align-self: stretch;
  background-color: #074faf;
  border-radius: 8px 0px 0px 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 14px 8px 8px;
  position: relative;
}

.app-view-free-trial-notify .icon-sets-time-alarm {
  height: 24px;
  position: relative;
  width: 24px;
}

.app-view-free-trial-notify .content-frame {
  align-items: flex-end;
  align-self: stretch;
  background-color: #ffffff;
  border: 2px solid;
  border-color: #074faf;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  justify-content: space-around;
  padding: 16px;
  position: relative;
}

.app-view-free-trial-notify .message-frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  left: 16px;
  position: absolute;
  top: 16px;
}

.app-view-free-trial-notify .text-wrapper {
  color: #000000;
  font-family: 'Montserrat-SemiBold', Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 33px;
  margin-top: -1px;
  position: relative;
  width: 100%;
}

.app-view-free-trial-notify .certification-text-wrapper {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  left: 16px;
  position: absolute;
  top: 50px;
}

.app-view-free-trial-notify .bold-text {
  font-family: 'Open Sans-Bold', Helvetica;
  font-weight: 700;
  font-size: 16px;
}

.app-view-free-trial-notify .certify-app-button-sub {
  align-items: center;
  display: flex;
  gap: 16px;
  height: 36.9px;
  justify-content: flex-end;
  left: 19px;
  padding: 0px 32px;
  position: absolute;
  top: 88px;
  width: 100%;
}

.app-view-free-trial-notify .button-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
  margin-right: 122px;
  margin-top: 26px;
}

.app-view-free-trial-notify .buttons-instance {
  flex: 0 0 auto !important;
}

.app-view-free-trial-notify .checkbox-wrapper {
  left: 40px;
  position: absolute;
  top: 8px;
  color: #6b2574;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.app-view-free-trial-notify .icon-sets-selection {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.app-view-free-trial-notify .button {
  background-color: unset !important;
  justify-content: center !important;
  left: 32px !important;
  padding: unset !important;
  position: absolute !important;
  top: 3px !important;
}

.free-trial-cancel-icon {
  bottom: 25px;
  left: 13px;
}

.sub-text {
  padding: 0px 0px 0px 14px;
  line-height: 20px;
  margin: 10px 0px 2px 0px;
  position: relative;
  width: 100%;
}

.title {
  padding: 0px 0px 0px 14px;
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15px;
}

.container {
  width: 66.5vw;
  border: 2px solid;
  border-color: #6b2574;
  border-radius: 8px;
  margin: 16px;
}

.icon-container {
  background-color: #6b2574;
  padding: 14px 8px 8px 8px;
}

.content {
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.checkbox-box {
  margin-top: -8px;
}

.cancel-app-button-wrapper {
  display: flex;
  align-items: center;
  margin-right: 14px;
}

.cancel-app-button-wrapper::before {
  content: '';
  display: inline-block;
  width: 1px;
  height: 16px;
  background-color: #000;
  margin-right: 14px;
}
