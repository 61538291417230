@use '@fortellis/style/variables' as frc-variables;

.fdn-image {
  max-width: 100%;
  max-height: 100%;

  &--right {
    padding-right: 70px;
    text-align: right;
  }
}

.fdn-home {
  &__tag {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 16px;
    color: #484848;
  }

  &__title {
    color: #202632;
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
  }

  &__subtitle {
    color: #904778;
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 40px;

    p,
    ul {
      font-weight: 400;
      font-size: 16px;
      color: #202632;
      line-height: 27px;
      letter-spacing: 1.46px;
    }

    ul {
      margin-left: -24px;
    }

    li {
      padding-left: 18px;
      color: #202632 !important;
    }
  }

  &__row {
    font-family: frc-variables.$fortellis-font-family;
    padding: 60px 0 90px 0;

    &--bg-light {
      background: #f8f8f8;
    }

    &--bg-dark {
      background: #202632;
      color: white;
      padding: 60px 70px 48px;

      h2 {
        color: white;
      }

      a {
        color: #904778;
        font-weight: 700;
        text-decoration: none;
      }

      p {
        font-size: 18px;
        margin-bottom: 0;
        letter-spacing: 0;
      }
    }
  }

  &__section {
    width: 50%;

    &--image {
      display: flex;
      align-items: center;
    }

    &--image-right {
      justify-content: flex-end;
    }

    &--text-left {
      padding-left: 70px;
    }

    &--text-right {
      padding-right: 70px;
      text-align: right;
    }
  }

  &__button {
    font-weight: 600 !important;
  }
}

.fdn-home-section {
  width: 1200px;
  max-width: 1200px;

  &__content {
    padding: 3em 0em;

    h2 {
      color: rgba(0, 0, 0, 0.87);
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      margin: 0;
    }

    h1 {
      color: #904778;
      font-weight: 300;
      font-size: 36px;
      line-height: 46px;
      margin: 0;
    }

    p {
      font-size: 18px;
      line-height: 27px;
      color: #202632;
      font-weight: 400px;
    }

    ul {
      padding-left: 20px;
      margin: 0;

      li {
        font-size: 18px;
        line-height: 27px;
        color: #202632;
        font-weight: 400px;
        padding-left: 16px;
      }
    }
  }
}

.fdn-home-section-bg {
  background-color: #efefef;
}

.fdn-home-card {
  width: 32%;

  // &__surface {
  //   background-color: #f5f5f5 !important;
  // }

  &__content {
    padding: 0.5em 1em 1em 1em;
    height: 145px;
  }

  &__header {
    font-size: 1.4rem;
    margin: 0.25em 0em;
    color: rgba(0, 0, 0, 0.87);
  }

  &__description {
    font-size: 1rem;
    margin: 0.25em 0em;
    color: rgba(0, 0, 0, 0.54);
  }
}

/** responsive styles */
@media (max-width: 1039px) {
  .fdn-home {
    &__row {
      &--bg-dark {
        padding-left: 0;
        padding-right: 0;
        p {
          margin-bottom: 18px !important;
        }
      }

      &--inner {
        flex-direction: column !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
      }
    }

    &__section {
      width: 100% !important;

      &--text-left {
        padding-left: 0 !important;
      }

      &--text-right {
        padding-right: 0 !important;
      }
    }

    &__card {
      flex-direction: column;
      margin: 80px 16px 0;
      padding: 16px 0 !important;
    }

    &__card-button {
      font-size: 16px !important;
    }

    &__pointer {
      padding-left: 12px !important;
      width: calc(100% - 47px) !important;
    }
  }

  .fdn-image {
    height: inherit !important;
    margin-top: 15px;
  }

  .fdn-home-section {
    width: 100%;

    &__content {
      padding: 3em 1em;
    }
  }

  .fdn-home-card {
    width: 90%;
    margin: 1em;

    &__content {
      height: 175px;
    }
  }
}
/** responsive styles */
