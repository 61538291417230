.fdn-api__learnmore {
  width: 81px;
  height: 18px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #5e2368;
}

.fdn-api__open_in_new {
  width: 16px;
  height: 16px;
  fill: #5e2368;
}

.fdn-api__anchor_tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}
