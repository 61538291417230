.frc-footer {
  &--cover {
    width: 100%;
  }
}

.frc-footer__row--account {
  background: url('../../assets/header-bg.jpg') center no-repeat;
  background-size: cover;
}

.frc-drawer__footer {
  &--primary {
    background: url('../../assets/header-bg.jpg') center no-repeat;
    background-size: cover;
  }
}
