.fdn-page__notfound {
  width: 100%;
  max-width: 1200px;
  margin: 32px auto;

  &-image {
    display: flex;

    @media (max-width: 1039px) {
      flex-direction: column;
      padding: 0em 2em;
    }

    img {
      @media (max-width: 1039px) {
        width: 100%;
      }
    }

    &-text {
      align-self: end;
      margin: 48px 0px;
      max-width: 425px;
      width: 100%;

      hr {
        width: 125px;
        border: none;
        border-top: 6px solid #e83a83;
        margin: 24px 0px;
      }

      h1,
      h2 {
        font-family: Montserrat, sans-serif;
        margin: 0px;
        text-transform: uppercase;
      }

      h1 {
        font-size: 36px;
        font-weight: 400;
        line-height: 36px;
        color: #461363;
      }

      h2 {
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 18px;
        color: #00aae7;
      }

      .redirect {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        text-decoration: none;
        color: #e83a83;
        border: none;
        background-color: transparent;
        padding: 0;

        &-icon {
          vertical-align: top;
          position: relative;
          width: 30px;
          height: 24px;

          .one,
          .two {
            position: absolute;
            top: 0;
          }
          .one {
            left: 0;
          }
          .two {
            left: 6px;
          }
        }
      }
    }
  }
}
