.frc-header {
  background: url('../../assets/header-bg.jpg') center no-repeat;
  background-size: cover;

  &--cover {
    background: transparent;
    position: absolute;
    width: 100%;
  }
}

.frc-drawer__header {
  &--primary {
    background: url('../../assets/header-bg.jpg') center no-repeat;
    background-size: cover;
  }
}
